import { Spinner } from '@shopify/polaris';
import React from 'react';
interface loader {
    color?: "teal" | "white" | "inkLightest" | "highlight" | undefined
}
const PageSpinner: React.FC<loader> = ({ color = 'teal'}) => {
    return (
        <div className="aec-spinner">
            <Spinner />
        </div>
    )
};

export default PageSpinner;