const initialState = {
    autoTaggingQueueNumber: 0,
    metafieldQueueNumber: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'autoTagging':
            return {
                ...state,
                autoTaggingQueueNumber: action.payload
            };
            case 'metafields':
            return {
                ...state,
                metafieldQueueNumber: action.payload
            };
        default:
            return { ...state }

    }

}

export default reducer;